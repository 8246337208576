import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    constructor(
        private http: HttpClient,
        private msalService: MsalService) {
    }

    get<T>(url: string): Promise<T> {
        return this.getHeaders().then(headers => {
            return this.http.get<T>(url, { headers })
                .pipe(
                    catchError((e) => this.handleError(e)),
                    map((data: T) => data)
                ).toPromise();
        });
    }

    post<T>(url: string, data: any): Promise<T> {
        return this.getHeaders().then(headers => {
            return this.http.post<T>(url, data, { headers })
                .pipe(
                    catchError(this.handleError),
                    map((error: T) => error)
                ).toPromise();
        });
    }

    put<T>(url: string, data: any): Promise<T> {
        return this.getHeaders().then(headers => {
            return this.http.put<T>(url, data, { headers })
                .pipe(
                    catchError(this.handleError),
                    map((error: T) => error)
                ).toPromise();
        });
    }

    delete<T>(url: string): Promise<T> {
        return this.getHeaders().then(headers => {
            return this.http.delete<T>(url, { headers })
                .pipe(
                    catchError(this.handleError),
                    map((error: T) => error)
                ).toPromise();
        });
    }

    private async getHeaders(): Promise<HttpHeaders> {
        const accounts: AccountInfo[] = this.msalService.instance.getAllAccounts();

        if (accounts.length === 0) {
            console.error('No signed-in accounts available.');
            return new HttpHeaders().append('Content-Type', 'application/json');
        }

        try {
            return this.msalService.acquireTokenSilent({
                account: accounts[0],
                scopes: [process.env.API_ACCESS]
            })
                .pipe(
                    map((response: any) => {
                        let headers = new HttpHeaders();
                        headers = headers.append('Authorization', `Bearer ${response.accessToken}`);
                        headers = headers.append('Content-Type', 'application/json');
                        return headers;
                    }),
                    catchError(error => {
                        console.error('Error obtaining the access token:', error);
                        return of(new HttpHeaders().append('Content-Type', 'application/json'));
                    })
                ).toPromise();
        } catch (error) {
            console.error('Error obtaining the access token:', error);
            return new HttpHeaders().append('Content-Type', 'application/json');
        }
    }

    private handleError(e: any) {
        console.log('Error', e);
        return throwError(new Error(e?.error || e));
    }
}
